@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calibri&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Helvetica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Verdana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');


/* sync Editor cscs */
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-documenteditor/styles/material.css";

body {
  font-family: "Open Sans", sans-serif;
}

option {
  color: black;
}
/* App.css */
.hide-scrollbar {
  overflow: auto;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  opacity: 0;  /* Initial hide */
  transition: opacity 0.3s ease;
}
.innerSource {
  background-color: #FFF7A3;
  page-break-inside: avoid;
  display: inline-block;
  font-family: 'Inter', sans-serif;
}

.outerSource {
  background-color: #FFF9C4;
  page-break-inside: avoid;
  display: inline-block;
}


.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.25em;
}

.hide-scrollbar:hover::-webkit-scrollbar {
  opacity: 1;  /* Show on hover */
}

.hide-scrollbar {
  scrollbar-width: thin;  /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;  /* For Firefox */
}
.react-calendar__tile--active {
  background-color: #9150EE !important;
  color: white;
}
.react-calendar__tile--rangeEnd {
  background: #4E13C3 !important;
}
.chakra-menu__menu-button{
  border: 1px solid;
  border-color: #E6E6E6 !important;
  background-color: white !important;
  color: #2B3674 !important;
  font-weight: 400 !important;
  font-family: "'DM Sans', sans-serif",
}
.react-calendar__navigation{
  margin-bottom: 0px !important;
}
.react-calendar__tile{
  color: #2B3674 ;
  font-weight: 400 !important;
  font-family: "'DM Sans', sans-serif",
}
